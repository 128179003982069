import React from 'react';
import { PageProps } from 'gatsby';

import Layout from '../components/site-layout/site-layout';
import { Head } from '../components/site-layout/head';
import PrivacyProtection from '../components/privacy/privacy';

const PrivacyProtectionPage: React.FC<PageProps<{}>> = ({ location }) => (
  <Layout location={location}>
    <Head title="Datenschutzerklärung" />
    <PrivacyProtection />
  </Layout>
);

export default PrivacyProtectionPage;
